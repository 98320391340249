// dependencies
import React, { useState, useMemo, useEffect } from 'react'
import { object, number, bool, func, string, arrayOf, objectOf, any, oneOfType, array } from 'prop-types'
import { useSelector } from 'react-redux'
import { currencyFormatUS, formatRelationshipsStrings } from '@helpers/string-helper'
import { abbreviateState, getRegionZone } from '@helpers/geo-location'
import { styled, useMediaQuery, useTheme, Hidden, Grid, Stack, Typography, Divider, Box } from '@mui/material'
import { AddonModelPropType } from '@models/api/products/addon-model'
import _isEmpty from 'lodash/isEmpty'
import { ADJUSTABLE_BASE_STRING, HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE } from '@constants/addons'

// helpers
import { getDateFull } from '@helpers/date'
import { useIsInViewport } from '@hooks/useIsInViewport'
import { useTest } from '@hooks/useTest'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { setOrderInfo } from '@helpers/checkout/global'
import { displayKlarnaPDPWidget } from '@helpers/finance'
import { formatReviews } from '@helpers/reviews'
import { filterOptionLabels, renderLTLmessage } from '@helpers/product'
import { scrollTo } from '@helpers/bodyscroll'
import { useMyStore } from '@hooks/useMyStore'
import { isEmpty } from 'lodash'

// components
import ShippingChangeZip from '@components/shared/ShopAndDeliverTo/shipping-change-zip'
import WarrantyModalTemplate from '@shared/modals/warranty-modal'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { FAVORITE_BUTTON } from '../../../@rtg2022/index'
import SleeperOptions from '../../../@rtg2022/components/organisms/PDP/SleeperOptions'
import ArrangementOptions from '../../../@rtg2022/components/organisms/PDP/ArrangementOptions'
import MovementOptions from '../../../@rtg2022/components/organisms/PDP/MovementOptions'
import ConsoleOptions from '../../../@rtg2022/components/organisms/PDP/ConsoleOptions'
import MaterialOptions from '../../../@rtg2022/components/organisms/PDP/MaterialOptions'
import RoomsPackages from '../../../@rtg2022/components/organisms/PDP/RoomsPackages/RoomsPackages'
import CheckboxFieldQuestion from '../../../@rtg2022/components/molecules/PDP/CheckboxFieldQuestion'
import Accordion from '../../../@rtg2022/components/organisms/PDP/Accordion'
import DeliveryInfo from '../../../@rtg2022/components/organisms/PDP/DeliveryInfo/DeliveryInfo'
import QuantityPicker from '../../../@rtg2022/components/molecules/PDP/QuantityPicker'
import Summary from '../../../@rtg2022/components/molecules/PDP/Summary/Summary'
import DesktopSummary from '../../../@rtg2022/components/molecules/PDP/DesktopSummary/DesktopSummary'
import Branding from '../../../@rtg2022/components/atoms/Branding/Branding'
import Button from '../../../@rtg2022/components/atoms/Button/Button'
import ProductPrice from '../../../@rtg2022/components/atoms/ProductPrice/ProductPrice'
import TitleBar from '../../../@rtg2022/components/molecules/PDP/TitleBar/TitleBar'
import ApplePayButton from '../../checkout/checkout-parts/payment-section/apple-pay'
import Relationships from './Relationships'
import ReviewsShort from '../../../@rtg2022/components/organisms/Reviews/reviews-short'
import Addons from '../views/Addons'

// hooks
import { useProduct, FETCH_PROPERTIES } from '../hooks'
import { useCart } from '../../cart/hooks'

const PREQUALIFIED_LINK = `&preQual=Y&sitecode=`

const StyledDesktopProductPrice = styled(ProductPrice)({
  padding: '.5rem 0',
  margin: '1rem 0',
})

const StyledTitleBar = styled(TitleBar)({
  padding: '15px 0',
  marginBottom: '8px',
})

const StyledBranding = styled(Branding)(({ theme }) => ({
  paddingBottom: '15px',
  [theme.breakpoints.down('md')]: {
    padding: '20px 0',
  },
}))

const StyledQuantityPicker = styled(QuantityPicker)({
  margin: '.8rem 0',
})

const StyledSku = styled('p')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: 'normal',
  color: theme.palette.secondary.dark,
}))

const StyledSummary = styled(Summary)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const ApplePayWrapper = styled('div')({
  display: 'flex',
  height: '40px',
})

const ItemsInRoomWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: '20px',
  paddingTop: '20px',
  '& > p': {
    marginRight: '10px',
  },
  '& > p:not(:first-of-type)': {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontWeight: '500',
  },
}))

const StyledFavoriteButton = styled(Button)(({ theme }) => ({
  paddingRight: '20px',
  p: {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: '15px',
    color: theme.palette.primary.main,
  },
}))

function checkIfPropertyExistsInEnhancedRelationships(enhancedRelationships, property) {
  return enhancedRelationships?.find(relationship => relationship[property]?.length > 0)
}

function RightRail({
  product,
  availability,
  stockMessage,
  quantity,
  setQuantity,
  warrantyEnabled,
  setWarrantyEnabled,
  showWarrantyModal,
  setShowWarrantyModal,
  quantityLimit,
  roomSavings,
  setUserSelectedAddons,
  requiredAddonsSelected,
  userSelectedAddons,
  hideTitleBar,
  foundationSelection,
  items_in_room,
  isFavorited,
  handleFavoriteClick,
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isNarrow = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const shippingAddress = useSelector(s => s?.location?.shipping_address)
  const { generateTestId } = useTest()

  const [displayLTLmessage, setDisplayLTLmessage] = useState(false)
  const assemblyRequired = product.customer_assembly_required
  const assemblyRequiredNote =
    assemblyRequired && (Object.is(product.delivery_type, 'O') || Object.is(product.delivery_type, 'U'))
      ? 'Assembly required'
      : ''

  const StyledAccordion = styled(Accordion)({})

  const StyledRoomsPackagesAccordion = styled(StyledAccordion)(({ theme }) => ({
    '.MuiAccordionSummary-content': {
      '.MuiStack-root': {
        'p:last-of-type': {
          color: theme.palette.secondary.lighter,
        },
      },
    },
  }))

  const StyledDeliveryInfo = styled(DeliveryInfo)({
    marginBottom: '20px',
  })

  const StyledReviewsShort = styled(ReviewsShort)(({ theme }) => ({
    '.MuiRating-root': {
      '.MuiRating-iconFilled': {
        svg: { fill: '#FFCB00 !important' },
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '1rem',
      marginTop: '.8rem',
    },
  }))

  const {
    sku,
    title,
    isMattress,
    routeToSimilarItems,
    image,
    price,
    enhancedRelationships,
    financeAmount,
    promotionalPrice,
    promotionalMonths,
    availableAddons,
    financeNumMonths,
    isStrikeThrough,
    priceStrikeThrough,
    sale,
    availabilityDate,
    colors,
    warrantyData,
    inStores,
    renderSeeInStoreButton,
    showFinance,
    arrangements,
    materials,
    movement,
    movementOptions,
    consoleOptions,
    sleeperOptions,
    isSectional,
    isLivingRoom,
    regionalSeeInRoom,
    roomsPackages,
    state: productState,
    city: productCity,
    reviews,
    category,
  } = useProduct(
    {
      product,
    },
    [FETCH_PROPERTIES.ROOMS_PACKAGES, FETCH_PROPERTIES.PRODUCT_REVIEWS],
  )

  const { region } = getRegionZone()
  const { renderAddToCartModal, onAddToCart } = useCart({
    quantity,
    availability,
    product,
    price,
    componentPage: 'pdp',
    stockMessage,
    addons: product?.addon_items,
    activeAddons: userSelectedAddons,
    requiredSelected: requiredAddonsSelected,
    warrantyEnabled,
    showLocation: false,
    moreInfoButton: false,
    region,
    source: 'pdp',
    index: 0,
  })

  const { myStore, relatedStores, renderMyStoreModalLocator, setMapUiDrawerIsOpen } = useMyStore()
  const isProductInStore = useMemo(
    () =>
      inStores?.includes(myStore?.storeNumber?.toString()) ||
      !!relatedStores?.filter(relatedStore => inStores?.includes(relatedStore?.storeNumber?.toString()))?.length,
    [inStores, myStore.storeNumber, relatedStores],
  )

  const initialShippingAddress = `${productCity}, ${abbreviateState(productState)}`

  const isProductTitleInViewport = useIsInViewport(
    typeof document !== 'undefined' && document?.getElementsByClassName('swiper-wrapper')?.[0],
  )

  const handleReviewsClick = () => {
    const reviewsButton = document.querySelector('.reviews-tab')
    reviewsButton?.click()
    scrollTo('store-reviews-accordion')
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'PDP Product Reviews',
        event: 'read reviews hotlink',
        action: 'click',
        productSku: product?.sku,
      })
    }
  }

  const [optionalAddons, setOptionalAddons] = useState(availableAddons?.filter(addon => !addon.addon_required))

  const theme = useTheme()

  // Remove Frames/Bases from addons when some foundation is selected (FD-1964)
  useEffect(() => {
    if (!isEmpty(foundationSelection) && foundationSelection?.title?.includes(ADJUSTABLE_BASE_STRING)) {
      setOptionalAddons(prev => prev.filter(addon => !HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE.includes(addon.sku)))
      setUserSelectedAddons(prev => prev.filter(addon => !HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE.includes(addon.sku)))
    } else {
      setOptionalAddons(availableAddons?.filter(addon => !addon.addon_required))
    }
  }, [availableAddons, foundationSelection, setOptionalAddons, setUserSelectedAddons])

  useEffect(() => {
    renderLTLmessage({
      delivery_sub_type_code: product?.delivery_sub_type_code,
      vendorId: product?.vendorId,
    }).then(setDisplayLTLmessage)
  }, [product])

  const noAddons =
    Object.is(region, 'OOM') ||
    Object.is(optionalAddons?.length, 0 || Object.is(product.warranty_pricing?.length, 0) || !getStateEligiblity()) ||
    !availability

  const formattedReviews = useMemo(() => formatReviews({ title, image, reviewsData: reviews }), [image, reviews, title])

  const formattedAvailabilityDate = new Date(availabilityDate) > new Date() && getDateFull(availabilityDate)
  const onAddToCartActions = useMemo(() => {
    if (!availability)
      return {
        action: 'SHOP SIMILAR PRODUCTS',
        onClick: routeToSimilarItems,
        testId: 'shopsimilar',
      }
    return {
      action: 'ADD TO CART',
      onClick: onAddToCart,
      testId: 'addtocart',
    }
  }, [availability, onAddToCart, routeToSimilarItems])

  const excludedOptions = [
    'glider',
    'lift',
    'adjustable back',
    'adjustable head rest',
    'adjustable height',
    'rocker',
    'swivel',
  ]

  const singleMovementOption =
    movement &&
    filterOptionLabels([
      {
        pdp_price: price,
        sku,
        label: movement.find(mov => !excludedOptions.includes(mov)),
      },
    ])

  const colorName = useMemo(
    () =>
      colors?.find(color => color?.sku === product?.sku)?.variation_value ||
      (colors?.length === 1 && colors?.[0]?.title),
    [colors, product.sku],
  )
  const materialName = useMemo(() => materials?.find(material => material?.sku === product?.sku)?.label, [
    materials,
    product.sku,
  ])
  const arrangementName = useMemo(() => arrangements?.find(arrangement => arrangement?.sku === product?.sku)?.label, [
    arrangements,
    product.sku,
  ])

  const movementName = useMemo(
    () =>
      movementOptions?.find(reclining => reclining?.sku === product?.sku)?.label ||
      singleMovementOption?.find(reclining => reclining?.sku === product?.sku)?.label,
    [product.sku, movementOptions, singleMovementOption],
  )
  const consoleName = useMemo(() => consoleOptions?.find(console => console?.sku === product?.sku)?.label, [
    product.sku,
    consoleOptions,
  ])
  const sleeperName = useMemo(() => sleeperOptions?.find(item => item?.sku === product?.sku)?.label, [
    product.sku,
    sleeperOptions,
  ])
  const displaySleeperSummary = useMemo(() => {
    const optionsInRegion = sleeperOptions?.filter(option => option.catalog_availability[region])
    return optionsInRegion?.length > 1
  }, [sleeperOptions, region])

  const summaryData = useMemo(() => {
    const data = []
    if (colorName && !enhancedRelationships?.[0]['chair color']?.length)
      data.push({ label: 'Color:', value: colorName })
    if (materialName) data.push({ label: 'Material:', value: materialName })
    if (arrangementName) data.push({ label: 'Arrangement:', value: arrangementName })
    if (movementName) data.push({ label: 'Movement:', value: movementName })
    if (consoleName) data.push({ label: 'Console:', value: consoleName })
    if (sleeperName && displaySleeperSummary) data.push({ label: 'Sleeper:', value: sleeperName })
    if (warrantyData) data.push({ label: 'Furniture Protection Plan:', value: warrantyEnabled ? 'Yes' : 'No', sort: 1 })

    return {
      data,
      shouldDisplaySummaryData: !!(
        availability &&
        (data.length > 0 ||
          enhancedRelationships?.[0]?.['table type']?.length > 0 ||
          enhancedRelationships?.[0]?.['side chair style']?.length > 0) &&
        (materialName || arrangementName || movementName || consoleName || sleeperName || enhancedRelationships)
      ),
    }
  }, [
    colorName,
    enhancedRelationships,
    materialName,
    arrangementName,
    movementName,
    consoleName,
    sleeperName,
    displaySleeperSummary,
    warrantyData,
    warrantyEnabled,
    availability,
  ])

  const showOptions =
    (movementOptions?.some(op => op?.label?.includes('power')) &&
      movementOptions?.forEach(op => !excludedOptions.includes(op?.label))) ||
    singleMovementOption[0]?.label?.includes('power')

  const showKlarna = displayKlarnaPDPWidget({ delivery_type: product.delivery_type, showFinance, price })

  const renderSeeInStores = () => (
    <Stack spacing={2} marginTop="16px">
      {renderSeeInStoreButton && (
        <>
          {renderMyStoreModalLocator({ product: { ...product, isProductInStore } })}
          <Button
            label="See In Store"
            variant="outlined"
            data-testid="seeInStoreButton"
            onClick={() => setMapUiDrawerIsOpen(true)}
            color="primary"
            style={{ width: '100%' }}
          />
        </>
      )}
    </Stack>
  )

  // Mobile
  const renderMobile = () => (
    <Stack>
      {warrantyData && (
        <WarrantyModalTemplate
          shouldShowModal={showWarrantyModal}
          closeModal={() => setShowWarrantyModal(false)}
          region={warrantyData?.provider_name}
        />
      )}
      {!hideTitleBar && (
        <>
          <StyledTitleBar
            title={product.displayNamePDP || product?.title}
            price={currencyFormatUS(price)}
            roomSavings={roomSavings}
            strikeThrough={isStrikeThrough}
            strikeThroughPrice={currencyFormatUS(priceStrikeThrough)}
            sale={sale}
            availability={availability}
          />
          <Divider />
        </>
      )}
      {product.brand && <StyledBranding brand={product.brand} />}
      {/* MOBILE REVIEWS SECTION */}
      {!_isEmpty(reviews) && (
        <>
          <StyledReviewsShort
            reviewsData={formattedReviews}
            onReviewsClick={handleReviewsClick}
            showAverageText={false}
          />
          <Divider />
        </>
      )}
      {/* MOBILE DELIVERY SECTION */}
      <StyledDeliveryInfo
        shippingAddress={typeof window === 'undefined' ? initialShippingAddress : shippingAddress}
        stockMessage={stockMessage}
        shippingEstimate={formattedAvailabilityDate}
        displayLTLmessage={displayLTLmessage}
        assemblyRequiredNote={assemblyRequiredNote}
      >
        <Box sx={{ paddingTop: '24px', paddingBottom: '24px' }}>
          <ShippingChangeZip open />
        </Box>
      </StyledDeliveryInfo>
      <Divider />
      {items_in_room && (
        <>
          <ItemsInRoomWrapper>
            <Typography fontWeight={600} textTransform="uppercase" color="primary.dark">
              Includes:
            </Typography>
            <Typography>
              {items_in_room
                .map(({ quantity: item_quantity, generic_name }) =>
                  item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                )
                .join(' • ')}
            </Typography>
          </ItemsInRoomWrapper>
          <Divider />
        </>
      )}
      {/* MOBILE KLARNA SECTION */}
      {showKlarna ? (
        <klarna-placement
          data-key="credit-promotion-auto-size"
          data-locale="en-US"
          data-purchase-amount={Math.trunc(price * 100)}
        />
      ) : null}
      {/* MOBILE MATERIAL SECTION */}
      {availability && materials?.length > 0 && (
        <>
          <MaterialOptions
            defaultExpanded
            disabled
            additionalTitle={materials?.length > 1 ? '' : materialName}
            options={materials}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}
      {/* MOBILE RELATIONSHIPS SECTION  */}
      {availability && (
        <>
          <Relationships
            sku={sku}
            enhancedRelationships={product?.enhancedRelationships}
            variations={product?.variations}
            colors={colors}
            items_in_room={product?.items_in_room?.[region]}
          />
          <Divider />
        </>
      )}
      {availability && arrangements?.length > 1 && (
        <>
          <ArrangementOptions
            additionalTitle={arrangements?.length > 1 ? null : arrangementName}
            defaultExpanded={isSectional}
            options={arrangements}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}
      {/* MOBILE CONSOLE SECTION */}
      {availability && consoleOptions?.length > 0 && (
        <>
          <ConsoleOptions
            productTitle={title}
            basePrice={price}
            additionalTitle={consoleOptions?.length > 1 ? null : consoleName}
            options={consoleOptions}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
            collection={product.collection}
          />
          <Divider />
        </>
      )}
      {/* MOBILE MOVEMENT SECTION */}
      {availability && (movementOptions?.length > 0 || singleMovementOption) && showOptions && (
        <>
          <MovementOptions
            additionalTitle={movementOptions?.length > 1 ? null : movementName}
            options={movementOptions?.length > 0 ? movementOptions : singleMovementOption}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}
      {/* MOBILE SLEEPER SECTION */}
      {availability && sleeperOptions?.length > 1 && (
        <>
          <SleeperOptions
            selectionGroup="sleeper"
            subTitle={sleeperOptions.find(i => i.sku === product.sku)?.label}
            itemComponent={StrapiLink}
            options={sleeperOptions}
            selectedProductSku={sku}
            pdpPrice={price}
          />
          <Divider />
        </>
      )}
      {/* MOBILE ROOMS PACKAGES SECTION */}
      {availability !== undefined && isLivingRoom && roomsPackages?.length > 0 && (
        <>
          <StyledRoomsPackagesAccordion
            selectionGroup="room-package"
            disabled
            defaultExpanded
            title="Buy the room & save:"
            additionalTitle={`${roomsPackages?.length} Package Option${roomsPackages?.length > 1 ? 's' : ''}`}
          >
            <RoomsPackages itemComponent={StrapiLink} image={regionalSeeInRoom?.[0]?.image} options={roomsPackages} />
          </StyledRoomsPackagesAccordion>
          <Divider />
        </>
      )}
      {warrantyData && getStateEligiblity() && (
        <>
          <StyledAccordion defaultExpanded disabled badgeNumber={2} title="Protect your purchase:">
            <CheckboxFieldQuestion
              checked={warrantyEnabled}
              onChange={setWarrantyEnabled}
              label={`Three (3) years furniture protection plan `}
              extraLabel={currencyFormatUS(warrantyData?.price)}
              additionalLink={{ title: `What's Covered?`, onClick: () => setShowWarrantyModal(true) }}
            />
          </StyledAccordion>
          <Divider />
        </>
      )}
      {/* TODO: FD-3477 - POC goes here: Begin */}
      {/* TODO: FD-3477 - POC goes here: End */}
      {/* MOBILE SUMMARY SECTION */}
      {summaryData?.shouldDisplaySummaryData && (
        <StyledAccordion defaultExpanded disabled title="Your selection summary">
          <DesktopSummary
            summaryData={[
              ...summaryData?.data.filter(
                item =>
                  item.label !== 'Color:' ||
                  !checkIfPropertyExistsInEnhancedRelationships(enhancedRelationships, 'finish'),
              ),
              ...Object.values(enhancedRelationships || [])
                .map(relationships => ({
                  finish:
                    relationships.finish?.length > 0
                      ? { label: 'Finish:', value: relationships.finish.find(item => item.sku === sku).label }
                      : null,
                  'chair color':
                    relationships['chair color']?.length > 0
                      ? {
                          label: 'Chair Color:',
                          value: relationships['chair color'].find(item => item.sku === sku).label,
                        }
                      : null,
                }))
                .map(item => Object.values(item).map(i => i))
                .reduce((acc, curr) => [...acc, ...curr], [])
                .filter(item => item),
            ]}
            imagesData={[...Object.values(enhancedRelationships || [])]
              .map(item =>
                item['table type']?.length > 0 || item['side chair style']?.length > 0
                  ? {
                      'table type':
                        item['table type']?.length > 0
                          ? {
                              src: item['table type']?.find(i => i.sku === sku).icon_image,
                              value: `${formatRelationshipsStrings(
                                item['table type']?.find(i => i.sku === sku).label,
                              )} ${category === 'diningroom' ? 'Dining Table' : ''}`,
                            }
                          : null,
                      'side chair style':
                        item['side chair style']?.length > 0
                          ? {
                              src: item['side chair style']?.find(i => i.sku === sku).icon_image,
                              value: formatRelationshipsStrings(
                                `${item['side chair style']?.find(i => i.sku === sku).label} Side Chair ${
                                  item['number of chairs']?.find(i => i.sku === sku)
                                    ? ` (${item['number of chairs']?.find(i => i.sku === sku).label})`
                                    : ''
                                }`,
                                true,
                                null,
                              ),
                            }
                          : null,
                    }
                  : [],
              )
              .map(item => Object.values(item).map(i => i))
              .reduce((acc, curr) => [...acc, ...curr], [])
              .filter(item => item)}
          />
        </StyledAccordion>
      )}
      {availability && (
        <QuantityPicker quantity={quantity} quantityLimit={quantityLimit} onQuantityChange={setQuantity} />
      )}
      <StyledSku>{`SKU: ${product?.sku}`}</StyledSku>
      <Hidden only="xs">
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: '16px',
          }}
          label={onAddToCartActions.action}
          fullWidth
          disableElevation
          disableRipple
          onClick={onAddToCartActions.onClick}
          data-testid={generateTestId(onAddToCartActions.testId, 'button')}
        />
      </Hidden>
      {availability && (
        <Stack spacing={2} marginTop="16px">
          {renderSeeInStoreButton && renderSeeInStores()}
          <StyledFavoriteButton
            variant="text"
            color="primary"
            fullWidth
            disableElevation
            disableRipple
            onClick={() => handleFavoriteClick(product)}
          >
            <FAVORITE_BUTTON
              checked={isFavorited}
              handleClick={handleFavoriteClick}
              sku={product?.sku}
              iconColor={isFavorited ? null : theme.palette.primary.main}
            />
            <p>{isFavorited ? 'IN YOUR FAVORITES' : 'ADD TO FAVORITES'}</p>
          </StyledFavoriteButton>
        </Stack>
      )}
      <StyledSummary
        name={product?.displayNamePDP || product?.title}
        price={currencyFormatUS(price)}
        strikeThrough={isStrikeThrough}
        strikeThroughPrice={currencyFormatUS(priceStrikeThrough)}
        sale={sale}
        availability={availability}
        financeAmount={financeAmount}
        promotionalPrice={promotionalPrice}
        promotionalMonths={promotionalMonths}
        numMonths={financeNumMonths}
        // showName={!isProductTitleInViewport}
        showName={false}
        addToCartLabel={onAddToCartActions.action}
        handleAddToCart={onAddToCartActions.onClick}
        prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d2&modalapply=true`}
        product={product}
        warrantyEnabled={warrantyEnabled}
        dataTestId={isNarrow ? generateTestId(onAddToCartActions.testId, 'button') : null}
        freeShippingMessage={product?.free_shipping ? 'Free Shipping' : null}
        isFavorited={isFavorited}
        handleFavoriteClick={handleFavoriteClick}
        roomSavings={roomSavings}
      />
      {!noAddons && (
        <Grid item>
          <Addons
            userSelectedAddons={userSelectedAddons}
            setUserSelectedAddons={setUserSelectedAddons}
            setWarrantyEnabled={setWarrantyEnabled}
            optionalAddons={optionalAddons}
            isMattress={isMattress}
          />
        </Grid>
      )}
    </Stack>
  )

  // Desktop
  const renderDesktop = () => (
    <Stack>
      {warrantyData && (
        <WarrantyModalTemplate
          shouldShowModal={showWarrantyModal}
          closeModal={() => setShowWarrantyModal(false)}
          region={warrantyData?.provider_name}
        />
      )}

      {product.brand && <StyledBranding brand={product.brand} />}

      {/* DESKTOP DELIVERY SECTION */}
      <StyledDeliveryInfo
        shippingAddress={typeof window === 'undefined' ? initialShippingAddress : shippingAddress}
        stockMessage={stockMessage}
        shippingEstimate={formattedAvailabilityDate}
        displayLTLmessage={displayLTLmessage}
        assemblyRequiredNote={assemblyRequiredNote}
      >
        <Box sx={{ paddingTop: '24px', paddingBottom: '24px' }}>
          <ShippingChangeZip open />
        </Box>
      </StyledDeliveryInfo>
      <Divider />

      {items_in_room && (
        <>
          <ItemsInRoomWrapper>
            <Typography fontWeight={600} textTransform="uppercase" color="primary.dark">
              Includes:
            </Typography>
            <Typography>
              {items_in_room
                .map(({ quantity: item_quantity, generic_name }) =>
                  item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                )
                .join(' • ')}
            </Typography>
          </ItemsInRoomWrapper>
          <Divider />
        </>
      )}

      {/* DESKTOP REVIEWS SECTION */}
      {!_isEmpty(reviews) && (
        <>
          <StyledAccordion defaultExpanded disabled badgeNumber={2} title="Product Review:">
            <StyledReviewsShort
              reviewsData={formattedReviews}
              onReviewsClick={handleReviewsClick}
              showAverageText={false}
            />
          </StyledAccordion>
          <Divider />
        </>
      )}

      {/* DESKTOP OPTIONS SECTION */}
      {availability && (
        <>
          <Relationships
            sku={sku}
            enhancedRelationships={product?.enhancedRelationships}
            variations={product?.variations}
            colors={colors}
            items_in_room={product?.items_in_room?.[region]}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP MATERIAL SECTION */}
      {materials?.length > 0 && availability && (
        <>
          <MaterialOptions
            defaultExpanded
            disabled
            additionalTitle={materials?.length > 1 ? '' : materialName}
            options={materials}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP ARRANGEMENT SECTION */}
      {arrangements?.length > 1 && availability && (
        <>
          <ArrangementOptions
            additionalTitle={arrangements?.length > 1 ? null : arrangementName}
            defaultExpanded={isSectional}
            options={arrangements}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP CONSOLE SECTION */}
      {consoleOptions?.length > 0 && availability && (
        <>
          <ConsoleOptions
            productTitle={title}
            basePrice={price}
            additionalTitle={consoleOptions?.length > 1 ? null : consoleName}
            options={consoleOptions}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
            collection={product.collection}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP RECLINING SECTION */}
      {(movementOptions?.length > 0 || singleMovementOption) && showOptions && availability && (
        <>
          <MovementOptions
            additionalTitle={movementOptions?.length > 1 ? null : movementName}
            options={movementOptions?.length > 0 ? movementOptions : singleMovementOption}
            itemComponent={StrapiLink}
            selectedProductSku={sku}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP SLEEPER SECTION */}
      {availability && sleeperOptions.length > 1 && (
        // make sure reclining options are not show repeated in case both are available
        <>
          <SleeperOptions
            selectionGroup="sleeper"
            subTitle={sleeperOptions.find(i => i.sku === product.sku)?.label}
            itemComponent={StrapiLink}
            options={sleeperOptions}
            selectedProductSku={sku}
            pdpPrice={price}
          />
          <Divider />
        </>
      )}

      {/* DESKTOP ROOMS PACKAGES SECTION */}
      {availability !== undefined && isLivingRoom && roomsPackages?.length > 0 && (
        <>
          <StyledRoomsPackagesAccordion
            selectionGroup="room-package"
            disabled
            defaultExpanded
            title="Buy the room & save:"
            additionalTitle={`${roomsPackages?.length} Package Option${roomsPackages?.length > 1 ? 's' : ''}`}
          >
            <RoomsPackages itemComponent={StrapiLink} image={regionalSeeInRoom?.[0]?.image} options={roomsPackages} />
          </StyledRoomsPackagesAccordion>
          <Divider />
        </>
      )}

      {warrantyData && getStateEligiblity() && (
        <>
          <StyledAccordion defaultExpanded disabled badgeNumber={2} title="Protect your purchase:">
            <CheckboxFieldQuestion
              onChange={setWarrantyEnabled}
              checked={warrantyEnabled}
              label={`Three (3) years furniture protection plan `}
              extraLabel={currencyFormatUS(warrantyData?.price)}
              additionalLink={{ title: `What's Covered?`, onClick: () => setShowWarrantyModal(true) }}
            />
          </StyledAccordion>
          <Divider />
        </>
      )}

      {/* DESKTOP SUMMARY SECTION */}
      {summaryData?.shouldDisplaySummaryData && (
        <StyledAccordion defaultExpanded disabled title="Your selection summary">
          <DesktopSummary
            summaryData={[
              ...summaryData?.data.filter(
                item =>
                  item.label !== 'Color:' ||
                  !checkIfPropertyExistsInEnhancedRelationships(enhancedRelationships, 'finish'),
              ),
              ...Object.values(enhancedRelationships || [])
                .map(relationships => ({
                  finish:
                    relationships.finish?.length > 0
                      ? { label: 'Finish:', value: relationships.finish.find(item => item.sku === sku).label }
                      : null,
                  'chair color':
                    relationships['chair color']?.length > 0
                      ? {
                          label: 'Chair Color:',
                          value: relationships['chair color'].find(item => item.sku === sku).label,
                        }
                      : null,
                }))
                .map(item => Object.values(item).map(i => i))
                .reduce((acc, curr) => [...acc, ...curr], [])
                .filter(item => item),
            ]}
            imagesData={[...Object.values(enhancedRelationships || [])]
              .map(item =>
                item['table type']?.length > 0 || item['side chair style']?.length > 0
                  ? {
                      'table type':
                        item['table type']?.length > 0
                          ? {
                              src: item['table type']?.find(i => i.sku === sku).icon_image,
                              value: `${formatRelationshipsStrings(
                                item['table type']?.find(i => i.sku === sku).label,
                              )} ${category === 'diningroom' ? 'Dining Table' : ''}`,
                            }
                          : null,
                      'side chair style':
                        item['side chair style']?.length > 0
                          ? {
                              src: item['side chair style']?.find(i => i.sku === sku).icon_image,
                              value: formatRelationshipsStrings(
                                `${item['side chair style']?.find(i => i.sku === sku).label} Side Chair ${
                                  item['number of chairs']?.find(i => i.sku === sku)
                                    ? ` (${item['number of chairs']?.find(i => i.sku === sku).label})`
                                    : ''
                                }`,
                                true,
                                null,
                              ),
                            }
                          : null,
                    }
                  : [],
              )
              .map(item => Object.values(item).map(i => i))
              .reduce((acc, curr) => [...acc, ...curr], [])
              .filter(item => item)}
          />
        </StyledAccordion>
      )}

      <StyledDesktopProductPrice
        availability={availability}
        price={currencyFormatUS(price)}
        strikeThrough={isStrikeThrough}
        strikeThroughPrice={currencyFormatUS(priceStrikeThrough)}
        sale={sale}
        financeAmount={financeAmount}
        promotionalPrice={promotionalPrice}
        promotionalMonths={promotionalMonths}
        monthly={financeNumMonths}
        variant="body1"
        prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d1&modalapply=true`}
        freeShippingMessage={product?.free_shipping ? 'Free Shipping' : null}
        roomSavings={roomSavings}
        // roomSavings={currencyFormatUS(roomSavings)}
      />

      {/* DESKTOP KLARNA SECTION */}
      {showKlarna ? (
        <klarna-placement
          data-key="credit-promotion-auto-size"
          data-locale="en-US"
          data-purchase-amount={Math.trunc(price * 100)}
        />
      ) : null}

      {availability && (
        <>
          <Divider />
          <StyledQuantityPicker quantity={quantity} quantityLimit={quantityLimit} onQuantityChange={setQuantity} />
        </>
      )}

      <StyledSku>{`SKU: ${product?.sku}`}</StyledSku>
      <Stack spacing={2} marginTop="20px">
        <Button
          variant="contained"
          color="primary"
          label={onAddToCartActions.action}
          fullWidth
          disableElevation
          disableRipple
          onClick={onAddToCartActions.onClick}
          data-testid={generateTestId(onAddToCartActions.testId, 'button')}
        />
        {renderSeeInStoreButton && availability && renderSeeInStores()}

        <StyledFavoriteButton
          variant="text"
          color="primary"
          fullWidth
          disableElevation
          disableRipple
          onClick={() => handleFavoriteClick(product)}
        >
          <FAVORITE_BUTTON
            checked={isFavorited}
            handleClick={handleFavoriteClick}
            sku={product?.sku}
            iconColor={isFavorited ? null : theme.palette.primary.main}
          />
          <p>{isFavorited ? 'IN YOUR FAVORITES' : 'ADD TO FAVORITES'}</p>
        </StyledFavoriteButton>

        {typeof window !== 'undefined' && window.safari !== undefined && (
          <ApplePayWrapper data-testid={generateTestId('addtocart button', 'applepay')}>
            <ApplePayButton
              type="Buy"
              isExpress
              isPDP
              setOrderInfo={() => setOrderInfo('Apple Pay', 'selectedPaymentType')}
              product={product}
              price={price}
              warrantyEnabled={warrantyEnabled}
              componentPage="pdp"
              activeAddons={userSelectedAddons}
            />
          </ApplePayWrapper>
        )}
      </Stack>
      <StyledSummary
        name={product?.displayNamePDP || product?.title}
        price={currencyFormatUS(price)}
        strikeThrough={isStrikeThrough}
        strikeThroughPrice={currencyFormatUS(priceStrikeThrough)}
        sale={sale}
        availability={availability}
        financeAmount={financeAmount}
        numMonths={financeNumMonths}
        promotionalPrice={promotionalPrice}
        promotionalMonths={promotionalMonths}
        showName={!isProductTitleInViewport}
        addToCartLabel={onAddToCartActions.action}
        handleAddToCart={onAddToCartActions.onClick}
        prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d2&modalapply=true`}
        freeShippingMessage={product?.free_shipping ? 'Free Shipping' : null}
        isFavorited={isFavorited}
        handleFavoriteClick={handleFavoriteClick}
      />
      {!noAddons && (
        <Grid item>
          <Addons
            userSelectedAddons={userSelectedAddons}
            setUserSelectedAddons={setUserSelectedAddons}
            setWarrantyEnabled={setWarrantyEnabled}
            optionalAddons={optionalAddons}
            isMattress={isMattress}
          />
        </Grid>
      )}
    </Stack>
  )

  return (
    <>
      {isMobile ? renderMobile() : renderDesktop()}
      {renderAddToCartModal()}
    </>
  )
}

RightRail.propTypes = {
  product: object,
  availability: bool,
  stockMessage: oneOfType([array, string]),
  quantity: number,
  setQuantity: func,
  warrantyEnabled: bool,
  setWarrantyEnabled: func,
  showWarrantyModal: bool,
  setShowWarrantyModal: func,
  roomSavings: oneOfType([number, string]),
  setUserSelectedAddons: func,
  userSelectedAddons: arrayOf(AddonModelPropType),
  requiredAddonsSelected: arrayOf(AddonModelPropType),
  hideTitleBar: bool,
  foundationSelection: objectOf(any),
  items_in_room: arrayOf(object),
  isFavorited: bool,
  handleFavoriteClick: func,
  quantityLimit: number,
}

export default RightRail
